<template functional>
    <div class="network_status">
        <a-icon type="exclamation-circle" />
        Проблема с интернет соединением. Проверьте подключение к интернету!
    </div>
</template>

<script>
export default {
    name: 'NetworkStatus'
}
</script>

<style lang="scss" scoped>
.network_status{
    position: fixed;
    z-index: 99999;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    background: rgba(0,0,0,0.6);
    color: #fff;
    padding: 15px;
    border-radius: 5px;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    &::v-deep{
        i{
            margin-right: 8px;
            color: #ff4d4f;
            font-size: 18px;
        }
    }
}
</style>